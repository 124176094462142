<template>
    <v-container fluid class="pa-0">
        <v-row v-if="$store.state.currentUser">
            <v-col cols="12" sm="10" md="8" lg="5">

                <v-form>
                    <v-container fluid class="px-0 pb-0">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Apotheken-IK" v-model="$store.state.currentUser.leistungserbringer"
                                              autocomplete="off" disabled dense hide-details />

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Name der Apotheke" v-model="$store.state.currentUser.apoName"
                                              autocomplete="off" disabled dense hide-details />

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Straße, Hausnummer" v-model="$store.state.currentUser.strasse"
                                              autocomplete="off" disabled dense hide-details />

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="5">
                                <v-text-field label="PLZ" v-model="$store.state.currentUser.plz"
                                              autocomplete="off" disabled dense hide-details />

                            </v-col>
                            <v-col cols="7">
                                <v-text-field label="Ort" v-model="$store.state.currentUser.ort"
                                              autocomplete="off" disabled dense hide-details />

                            </v-col>
                        </v-row>
                        <v-row class="align-baseline">
                            <v-col cols="auto" class="flex-grow-1">
                                <v-text-field label="Email" v-model="emailNew" :rules="emailRules"
                                              autocomplete="email" clearable dense hide-details="auto" />

                                <v-text-field v-if="emailOld !== emailNew" class="mt-7"
                                              label="Email-Adresse bestätigen" v-model="vEmailWiederholen" :rules="emailRules"
                                              autocomplete="email" clearable dense hide-details="auto" />

                            </v-col>
                            <v-col cols="auto" class="pl-0">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" @click="showDialog = true;" icon
                                               :disabled="!isValid || (emailOld === emailNew && vEmailWiederholen !==emailNew)">

                                            <v-icon color="primary">$vuetify.icons.contentSave</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Email-Adresse speichern</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <p class="text-body-2 font-weight-semibold">
                                    Hinweis: Falls Sie eine Adressänderung wünschen, nehmen Sie bitte Kontakt mit der
                                    <a href="https://www.dguv.de/arge-ik/index.jsp" target="_blank">ARGE IK</a> auf.
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

            </v-col>
        </v-row>

        <v-dialog v-model="showDialog">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.email</v-icon>
                    E-Mail-Adresse &auml;ndern
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">

                    <v-text-field label="Bitte Passwort eingeben" v-model="vPassword"
                                  :type="showPasswort ? 'text' : 'password'"
                                  :append-icon="showPasswort ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="() => (showPasswort = !showPasswort)"
                                  required clearable />

                    <v-alert border="left" type="error" v-if="errorMessage" class="mt-4">
                        {{ errorMessage }}
                    </v-alert>

                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" type="submit"
                           @click.stop.prevent="doSave">

                        Speichern
                    </v-btn>
                    <v-btn color="primary" type="button" 
                           @click.stop.prevent="doCancel">

                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import eventBus from '@/main';
    export default {
        name: 'Stammdaten',

        data() {
            return {
                showDialog: false,
                vPassword: '',
                emailRules: [
                    v => !!v || 'Email-Adresse ist erforderlich',
                    v => /^.+@.+\..+$/.test(v) || 'Geben Sie eine gültige E-Mail-Adresse ein'
                ],
                emailNew: null,
                emailOld: null,
                vEmailWiederholen: null,
                errorMessage: '',
                errorCount: 0,
                showPasswort: false,
            }
        },

        watch: {
            vPassword() {
                if (this.showDialog && this.errorMessage !== "" && this.vPassword !== "") {
                    this.errorMessage = "";
                }
            }
        },

        created() {
            this.emailOld = this.emailNew = this.$store.state.currentUser.email;
        },

        computed: {
            isValid() {
                return this.emailNew === this.vEmailWiederholen;
            }
        },

        methods: {

            doCancel() {
                this.showDialog = false;

                //die alte E-Mail-Adresse anzeigen
                this.emailNew = this.emailOld;
                this.vEmailWiederholen = null;
            },

            doSave() {
                this.loading = true;
                eventBus.$emit('resetLogoutTimer', {});
                this.$http.post('auth/emailchange', {
                    Username: this.$store.state.currentUser.loginName,
                    Password: this.vPassword,
                    EMail: this.emailNew

                }).then((response) => {
                    if (response.status === 200) {

                        let currentUser = this.$store.state.currentUser;
                        currentUser.email = this.emailNew;
                        this.$store.commit('updateCurrentUser', currentUser);

                        this.showDialog = false;
                        this.vPassword = '';
                        this.showPasswort = false;
                        this.emailOld = this.emailNew;
                        this.vEmailWiederholen = null;
                    }
                }).catch(error => {
                    if (error.response) {
                        this.errorMessage = error.response.data.message;
                        this.errorCount += 1;
                        if (this.errorCount < 3) {
                            this.errorMessage += "\nBitte geben sie Ihr Passwort erneut!";
                        }
                        else {
                            this.errorMessage += "\nSie haben das Passwort dreimal falsch eingegeben. Sie werden gleich ausgeloggt!"
                            this.showDialog = false;
                            this.showPasswort = false;
                            this.emailNew = this.emailOld;
                            this.vEmailWiederholen = null;
                        }
                        this.vPassword = '';
                    }

                }).finally(() => this.loading = false);
            }

        }
    }
</script>